import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Text from './text'

const TextList = ({ style, list, parentEl, singleText }) => {
  return (
    <div
      css={[
        css`
          ${tw`w-full flex flex-col space-y-8 lg:(grid gap-x-16 grid-cols-4 space-y-0) xl:grid-cols-4`}
          p:not(:first-of-type) {
            ${tw`mt-6`}
          }
          b,
          strong {
            ${tw`font-medium`}
          }
        `,
        style,
      ]}
    >
      {list &&
        list.map((content, i) => (
          <Text
            key={i}
            parentEl={parentEl}
            content={content}
            style={
              !singleText || singleText === 'undefined'
                ? tw`font-normal lg:col-span-2`
                : tw`font-normal lg:col-span-3`
            }
          />
        ))}
    </div>
  )
}

TextList.defaultProps = {
  parentEl: 'h6',
}

TextList.propTypes = {
  style: StyleType,
  list: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  parentEl: PropTypes.string,
  singleText: PropTypes.bool,
}

export default TextList
